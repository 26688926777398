import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Spin, Row, Col, Button, Tabs } from 'antd';
import ProductFormModal from '../../components/Products/ProductFormModal';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import { EventModel } from '../../models/events/events';
import { useProductStore } from '../../store/productStore';
import { useTranslation } from 'react-i18next';

const ProductDetailsScreen: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();
    const {
        isLoading: productLoading,
        selectedItem,
        setSelectedItem,
        productEvents,
        fetchEventsByProduct,
        fetchItemById
    } = useProductStore();
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('1');
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!selectedItem || selectedItem.id !== productId) {
            fetchItemById(productId!);
            fetchEventsByProduct(productId!);
        }
    }, [productId, selectedItem, fetchItemById]);

    const handleEdit = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedItem(null);
    };

    const eventColumns: DataTableColumn<EventModel>[] = [
        { header: t('events.name'), accessor: 'name' },
        { header: t('events.description'), accessor: 'description' },
        { header: t('events.startDate'), accessor: 'startDate', type: 'date' },
        { header: t('events.endDate'), accessor: 'endDate', type: 'date' }
    ];

    const handleRowClick = (event: EventModel) => {
        navigate(`/events/details/${event.id}`);
    };

    const tabItems = [
        {
            key: '1',
            label: t('events.title'),
            children: (
                <DataTable
                    columns={eventColumns}
                    data={productEvents || []}
                    onRowClick={handleRowClick}
                    enableFrontendFiltering
                />
            ),
        },
    ];

    if (productLoading) {
        return <Spin>Loading...</Spin>;
    }

    return (
        <div style={{ padding: '0px 20px' }}>
            <Card
                title={`${selectedItem?.name}`}
                style={{ marginBottom: '20px' }}
                extra={
                    <Button type="primary" onClick={handleEdit}>
                        {t('products.editProduct')}
                    </Button>
                }
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label={t('products.name')}>{selectedItem?.name}</Descriptions.Item>
                            <Descriptions.Item label={t('products.shortName')}>{selectedItem?.shortName}</Descriptions.Item>
                            <Descriptions.Item label={t('products.serviceCostPrice')}>{selectedItem?.serviceCostPrice} €</Descriptions.Item>
                            <Descriptions.Item label={t('products.creditNotePrice')}>{selectedItem?.creditNotePrice} €</Descriptions.Item>
                            <Descriptions.Item label={t('products.quantityPerBox')}>{selectedItem?.quantityPerBox}</Descriptions.Item>
                            <Descriptions.Item label={t('products.type')}>{selectedItem?.type?.name}</Descriptions.Item>
                            <Descriptions.Item label={t('products.notes')}>{selectedItem?.notes}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Card>

            <Tabs
                defaultActiveKey="1"
                activeKey={selectedTab}
                onChange={(key) => setSelectedTab(key)}
                items={tabItems}
            />

            <ProductFormModal
                open={isModalOpen}
                onClose={handleCloseModal}
                initialValues={selectedItem}
            />
        </div>
    );
};

export default ProductDetailsScreen;
