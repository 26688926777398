/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal } from 'antd';
import { FormikHelpers, FormikValues } from 'formik';

interface GenericModalProps<T> {
  open: boolean;
  title: string;
  onClose: () => void;
  initialValues: T;
  onSubmit: (values: FormikValues, actions: FormikHelpers<FormikValues>) => Promise<void>;
  fields: Array<{
    label: string;
    name: string;
    type: string;
    placeholder: string;
    component?: React.ElementType;
    options?: { value: string; label: string }[];
  }>;
  FormComponent: React.FC<{
    initialValues: T;
    onSubmit: (values: FormikValues, actions: FormikHelpers<FormikValues>) => Promise<void>;
    onCancel: () => void;
    fields: any[];
  }>;
}

const DataFormModal = <T,>({
  open,
  title,
  onClose,
  initialValues,
  onSubmit,
  fields,
  FormComponent,
}: GenericModalProps<T>) => {
  return (
    <Modal title={title} open={open} onCancel={onClose} footer={null} width={600}>
      <FormComponent
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onClose}
        fields={fields}
      />
    </Modal>
  );
};

export default DataFormModal;
