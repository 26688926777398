import { create } from 'zustand';
import agent from '../api/agent';
import { createBaseStore } from './util/createBaseStore';
import { OrderItem } from '../models/orderItems/orderItem';
import { EntityStore } from './util/interfaces/entityStore';
import { byKeyResults, OfflineStoreKeys } from '../offlinestore/offline.util';

interface OrderItemsStore extends EntityStore<OrderItem> {
    fetchOrderItemsByEventId: (eventId: string, updateStore: boolean) => Promise<OrderItem[]>;
}

const useBaseOrderItemStore = create<OrderItemsStore>((set, get) => ({
    ...createBaseStore<OrderItem>(agent.OrderItems, OfflineStoreKeys.ORDER_ITEMS)(set, get, {} as any),
    fetchOrderItemsByEventId: async (eventId: string, updateStore: boolean) => {
        set({ isLoading: true, error: null });
        try {
            const orderItemsByEvent = await agent.OrderItems.getAllByEventId(eventId);
            const offlineOrderItemsByEvent = await byKeyResults(orderItemsByEvent, 'eventId', eventId, get().storeKey) as any;
            if (updateStore) set({ items: navigator.onLine ? orderItemsByEvent : offlineOrderItemsByEvent });
            return navigator.onLine ? orderItemsByEvent : offlineOrderItemsByEvent;
        } catch (error) {
            if (eventId) {
                const offlineOrderItemsByEvent = await byKeyResults([], 'eventId', eventId, get().storeKey) as any;
                if (updateStore) set({ items: offlineOrderItemsByEvent });
            }
        } finally {
            set({ isLoading: false });
        }
    },
}));


export default useBaseOrderItemStore;
