import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import CustomerFormModal from '../../components/Customers/CustomerFormModal';
import useCustomerStore from '../../store/customerStore';
import { Customer } from '../../models/customers';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/authUserStore';

const Customers: React.FC = () => {
  const { items: items, selectedItem, fetchItems, setSelectedItem, deleteItem } = useCustomerStore();
  const { currentUser } = useAuthStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEdit = (customerId: string) => {
    const item = items.find((x) => x.id === customerId);
    setSelectedItem(item!);
    setModalOpen(true);
  };

  const handleRowClick = (customerId: string) => {
    navigate(`/customers/${customerId}`);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const columns: DataTableColumn<Customer>[] = [
    { header: t('customer.firstName'), accessor: 'firstName', type: 'text' },
    { header: t('customer.lastName'), accessor: 'lastName' },
    { header: t('customer.companyName'), accessor: 'companyName' },
    { header: t('customer.username'), accessor: 'username' },
    { header: t('customer.phone'), accessor: 'phone' },
    { header: t('customer.email'), accessor: 'email' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems])

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>Customers</span>
            {currentUser?.role === 'admin' &&
              <Button type="primary" onClick={() => setModalOpen(true)}>
                {t('customer.create')}
              </Button>
            }
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onEdit={handleEdit}
          onRowClick={(customer) => handleRowClick(customer.id!)}
          onDelete={deleteItem}
          enableFrontendFiltering
        />
      </Card>
      <CustomerFormModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialValues={selectedItem}
      />
    </>
  );
};

export default Customers;
