import { create } from 'zustand';
import agent from '../api/agent';
import { createBaseStore } from './util/createBaseStore';
import { User } from '../models/users';
import { OfflineStoreKeys } from '../offlinestore/offline.util';

const useBaseUserStore = create(createBaseStore<User>(agent.Users, OfflineStoreKeys.USERS));

export const useUserStore = useBaseUserStore;

export default useUserStore;
