import { create } from 'zustand';
import agent from '../api/agent';
import { Order } from '../models/orders/order';
import { createBaseStore } from './util/createBaseStore';
import { EntityStore } from './util/interfaces/entityStore';
import { byKeyResults, OfflineStoreKeys } from '../offlinestore/offline.util';

interface OrderStore extends EntityStore<Order> {
    selectedStandOrders: Order[];
    selectedCustomerOrders: Order[];
    fetchOrdersByStandId: (standId: string) => Promise<void>;
    fetchOrdersByCustomerId: (customerId: string) => Promise<void>;
    downloadPDFByBillType: (orderId: string, billType: string) => Promise<void>;
    fetchOrdersByEventId: (eventId: string, updateStore: boolean) => Promise<Order[]>;
}

export const useOrderStore = create<OrderStore>((set, get) => ({
    ...createBaseStore<Order>(agent.Orders, OfflineStoreKeys.ORDERS)(set, get, {} as any),
    selectedStandOrders: [],
    selectedCustomerOrders: [],
    fetchOrdersByStandId: async (standId: string) => {
        set({ isLoading: true, error: null });
        try {
            const orders = await agent.Orders.getByStandId(standId);
            const offlineOrders = await byKeyResults(orders, 'standId', standId, get().storeKey) as any;
            set({ selectedStandOrders: navigator.onLine ? orders : offlineOrders || [] });
        } catch (error) {
            const offlineOrders = await byKeyResults([], 'standId', standId, get().storeKey) as any;
            set({ selectedStandOrders: offlineOrders });
        } finally {
            set({ isLoading: false });
        }
    },
    fetchOrdersByCustomerId: async (customerId: string) => {
        set({ isLoading: true, error: null });
        try {
            const orders = await agent.Orders.getByCustomerId(customerId);
            const offlineOrders = await byKeyResults(orders, 'customerId', customerId, get().storeKey) as any;
            set({ selectedCustomerOrders: navigator.onLine ? offlineOrders : offlineOrders || [] });
        } catch (error) {
            const offlineOrders = await byKeyResults([], 'customerId', customerId, get().storeKey) as any;
            set({ selectedCustomerOrders: offlineOrders });
        } finally {
            set({ isLoading: false });
        }
    },
    downloadPDFByBillType: async (orderId: string, billType: string) => {
        try {
            const order: Order = await agent.Orders.getById(orderId);
            const response = await agent.Orders.downloadPDFByBillType(orderId, billType);
            const blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${order.orderNumber!}-${billType}.pdf`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

        } catch (err) {
            console.error("Error downloading PDF:", err);
        }
    },
    fetchOrdersByEventId: async (eventId: string, updateStore: boolean) => {
        set({ isLoading: true, error: null });
        try {
            const orders = await agent.Orders.getAllByEventId(eventId);
            const offlineOrders = await byKeyResults(orders, 'eventId', eventId, get().storeKey) as any;
            return navigator.onLine ? orders : offlineOrders;
        } catch (error) {
            if (eventId) {
                return byKeyResults([], 'eventId', eventId, get().storeKey) as any;
            }
        } finally {
            set({ isLoading: false });
        }
    },
}));

export default useOrderStore;
