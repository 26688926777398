/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Layout, Typography, Image, Badge, Select, Checkbox } from 'antd';
import './HeaderComponent.css';
import { useTranslation } from 'react-i18next';
import useEventStore from '../../store/eventStore';
import { eventIdField, idField, pendingStatus } from '../../models/staticFields';
import useProductStore from '../../store/productStore';
import useCustomerStore from '../../store/customerStore';
import useProductTypeStore from '../../store/productTypeStore';
import useStandTypeStore from '../../store/standTypeStore';
import useStandStore from '../../store/standStore';
import useOrderStore from '../../store/orderStore';
import useOrderItemStore from '../../store/orderItemStore';
import useUserStore from '../../store/userStore';
import { useAuthStore } from '../../store/authUserStore';
import { UserRole } from '../../models/users';
import useCountryStore from '../../store/countryStore';
import { offlineStoreDb } from '../../offlinestore/offlineStoreDb';
import useStandSectorStore from '../../store/standSectoreStore';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;
const { Title } = Typography;

const HeaderComponent: React.FC = () => {
  const eventsStore = useEventStore();
  const usersStore = useUserStore();
  const standsStore = useStandStore();
  const standSectorStore = useStandSectorStore();
  const orderStore = useOrderStore();
  const orderItemsStore = useOrderItemStore();
  const productsStore = useProductStore();
  const customersStore = useCustomerStore();
  const productTypesStore = useProductTypeStore();
  const standTypesStore = useStandTypeStore();
  const countriesStore = useCountryStore();
  const { token } = useAuthStore();
  const navigate = useNavigate();
  const [online, setOnline] = useState<boolean>(navigator.onLine);
  const { currentUser } = useAuthStore();
  const { t, i18n } = useTranslation();


  const refreshOfflineDb = async () => {
    // cleanup
    const pendingEventsIds = (await eventsStore.fetchEventsByStatus(pendingStatus))?.map(pe => pe.id);
    if (pendingEventsIds?.length > 0) {
      await offlineStoreDb.events.where(idField).noneOf(pendingEventsIds).delete();
      await offlineStoreDb.eventProducts.where(eventIdField).noneOf(pendingEventsIds).delete();
      await offlineStoreDb.eventCustomers.where(eventIdField).noneOf(pendingEventsIds).delete();
      await offlineStoreDb.orderItems.where(eventIdField).noneOf(pendingEventsIds).delete();
      await offlineStoreDb.orders.where(eventIdField).noneOf(pendingEventsIds).delete();
      await offlineStoreDb.standSectors.where(eventIdField).noneOf(pendingEventsIds).delete();
      await offlineStoreDb.stands.where(eventIdField).noneOf(pendingEventsIds).delete();
      await offlineStoreDb.events.where(idField).noneOf(pendingEventsIds).delete();
    }

    // setup
    await productsStore.fetchItems();
    await customersStore.fetchItems();
    await usersStore.fetchItems();
    await productTypesStore.fetchItems();
    await standTypesStore.fetchItems();
    await countriesStore.fetchItems();
    (await eventsStore.fetchEventsByStatus(pendingStatus))?.map(async pendingEvent => {
      await standsStore.fetchStandsByEventId(pendingEvent.id, false);
      await standSectorStore.fetchStandSectorsByEventId(pendingEvent.id, false)
      await orderStore.fetchOrdersByEventId(pendingEvent.id, false);
      await orderItemsStore.fetchOrderItemsByEventId(pendingEvent.id, false);
      await customersStore.fetchCustomersByEvent(pendingEvent.id, false);
      await productsStore.fetchProductsByEvent(pendingEvent.id, false);
    });
  }

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => {
      setOnline(false);
      navigate('/');
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (token && navigator.onLine) {
      refreshOfflineDb();
    }
  }, [token]);

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <Header className="header">
      <div className="logo">
        <Image src="/logo.png" preview={false} style={{ marginRight: '1rem', height: '40px' }} />
        <Title level={3} style={{ color: 'white', margin: 0, lineHeight: '20px' }}>
          CupCycle {t('general.eventManager')}
        </Title>
      </div>
      <div className="status-indicator">
        {currentUser?.role === UserRole.ADMIN && < Badge
          status={online ? 'success' : 'error'}
          text={online ? t('general.online') : t('general.offline')}
          style={{ marginLeft: 'auto', lineHeight: '40px', color: 'white' }}
        />}
      </div>
      <Select
        className="select-language"
        style={{
          width: 50,
          background: 'transparent',
          border: 'none',
        }}
        dropdownStyle={{ padding: 0, background: 'transparent' }}
        value={i18n.language}
        onChange={handleChangeLanguage}
        suffixIcon={null}
        options={[
          {
            value: 'de',
            label: (
              <Image
                style={{ border: '1px solid gray' }}
                src="/de.png"
                preview={false}
                alt="German"
                width={25}
              />
            )
          },
          {
            value: 'en',
            label: (
              <Image
                style={{ border: '1px solid gray' }}
                src="/gb.png"
                preview={false}
                alt="English"
                width={25}
              />
            )
          }
        ]}
        variant="filled"
      />
    </Header>
  );
};

export default HeaderComponent;
