import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import EventFormModal from '../../components/Events/EventFormModal';
import useEventStore from '../../store/eventStore';
import { EventModel } from '../../models/events/events';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Events: React.FC = () => {
  const { items: items, fetchItems, deleteItem } = useEventStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventModel | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRowClick = (event: EventModel) => {
    navigate(`/events/details/${event.id}`);
  };

  const handleEdit = (eventId: string) => {
    const item = items.find((x) => x.id === eventId);

    if (item) {
      setSelectedEvent({ ...item, userId: item.user?.id! });
    }

    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  const columns: DataTableColumn<EventModel>[] = [
    { header: t('general.name'), accessor: 'name' },
    { header: t('events.description'), accessor: 'description' },
    { header: t('events.accountant'), accessor: 'user.username' },
    { header: t('events.startDate'), accessor: 'startDate', type: 'date' },
    { header: t('events.endDate'), accessor: 'endDate', type: 'date' },
    { header: t('events.status'), accessor: 'status', renderCell: (item) => t(`events.${item.status}`), hideFilter: true },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('events.title')}</span>
            <Button type="primary" onClick={() => setModalOpen(true)}>
              {t('events.createEvent')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onRowClick={handleRowClick}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
      <EventFormModal open={isModalOpen} onClose={handleCloseModal} initialValues={selectedEvent} />
    </>
  );
};

export default Events;
