import { create } from 'zustand';
import agent from '../api/agent';
import { Stand } from '../models/stands/stand';
import { createBaseStore } from './util/createBaseStore';
import { EntityStore } from './util/interfaces/entityStore';
import { byKeyResults, OfflineStoreKeys } from '../offlinestore/offline.util';

interface StandStore extends EntityStore<Stand> {
    fetchStandsByCustomerId: (customerId: string) => Promise<Stand[]>;
    fetchStandsByEventId: (eventId: string, updateStore: boolean) => Promise<Stand[]>;
    customerStands: Stand[];
    eventStands: Stand[];
}

const useStandStore = create<StandStore>((set, get) => ({
    ...createBaseStore<Stand>(agent.Stands, OfflineStoreKeys.STANDS)(set, get, {} as any),
    customerStands: [],
    eventStands: [],
    fetchStandsByCustomerId: async (customerId: string) => {
        set({ isLoading: true, error: null });
        try {
            const stands = await agent.Stands.getByCustomerId(customerId);
            const offlineStands = await byKeyResults(stands, 'customerId', customerId, get().storeKey);
            set({ customerStands: navigator.onLine ? stands : offlineStands });
            return navigator.onLine ? stands : offlineStands;
        } catch (error) {
            if (customerId) {
                const offlineStands = await byKeyResults([], 'customerId', customerId, get().storeKey);
                set({ customerStands: offlineStands });
            }
            set({ isLoading: false });
        }
    },
    fetchStandsByEventId: async (eventId: string, updateStore: boolean) => {
        set({ isLoading: true, error: null });
        try {
            const stands = await agent.Stands.getAllByEventId(eventId);
            const offlineStands = await byKeyResults(stands, 'eventId', eventId, get().storeKey);
            if (updateStore) set({ eventStands: navigator.onLine ? stands : offlineStands });
            return navigator.onLine ? stands : offlineStands;
        } catch (error) {
            if (eventId) {
                const offlineStands = await byKeyResults([], 'eventId', eventId, get().storeKey);
                if (updateStore) set({ eventStands: offlineStands });
            }
        } finally {
            set({ isLoading: false });
        }
    },
}));

export default useStandStore;
