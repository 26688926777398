import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tabs, Card, Col, Descriptions, Row, Checkbox, Modal, Radio, Spin } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import useEventStore from '../../store/eventStore';
import DataTable from '../../components/DataTable/DataTable';
import AddStandModal from '../../components/Stands/AddStandModal';
import StandSectorFormModal from '../../components/StandSectors/StandSectorFormModal';
import { Stand } from '../../models/stands/stand';
import { StandSector } from '../../models/standSectors/standSector';
import useStandSectorStore from '../../store/standSectoreStore';
import DataFormModal from '../../components/DataForm/DataFormModal';
import { Product } from '../../models/products/products';
import { FormikValues } from 'formik';
import DataForm from '../../components/DataForm/DataForm';
import useStandStore from '../../store/standStore';
import { useProductStore } from '../../store/productStore';
import { useTranslation } from 'react-i18next';
import { Util } from '../../store/util/util';
import EventFormModal from '../../components/Events/EventFormModal';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Customer } from '../../models/customers';
import useCustomerStore from '../../store/customerStore';
import useStandTypeStore from '../../store/standTypeStore';

const EventDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { selectedItem: selectedEvent, isLoading: isLoadingEvent, fetchItemById: fetchEventById, setSelectedItem,
    downloadInvoiceByCustomerId, downloadIssuedArticlesReportByCustomerId, downloadIssuedEventArticlesByCustomerReport, downloadIssuedEventArticlesByProductReport } = useEventStore();
  const { items: standTypes, fetchItems: fetchStandTypes } = useStandTypeStore();
  const { eventStands, fetchStandsByEventId, isLoading: isLoadingStsands, selectedItem: selectedStand, setSelectedItem: setSelectedStand, deleteItem: deleteStand } = useStandStore();
  const { sectorsByEventId, fetchStandSectorsByEventId, deleteItem: deleteStandSector } = useStandSectorStore();
  const { items: products, isLoading: isLoadingProducts, productsOfSelectedEvent, addProductToEvent, deleteProductFromEvent, fetchItems: fetchProducts, fetchProductsByEvent } = useProductStore();
  const { items: customers, selectedItem: selectedCustomer, isLoading: isLoadingCustomers, setSelectedItem: setSelectedCustomer, customersOfSelectedEvent, addCustomerToEvent, deleteCustomerFromEvent, fetchItems: fetchCustomers, fetchCustomersByEvent } = useCustomerStore();
  const [modalOpen, setModalOpen] = useState(false);
  const [editEventModalOpen, setEditEventModalOpen] = useState(false);
  const [sectorModalOpen, setSectorModalOpen] = useState(false);
  const [selectedSector, setSelectedSector] = useState<StandSector | null>(null);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [selectedStandType, setSelectedStandType] = useState<string | null>('all');
  const [showStandTypeModal, setShowStandTypeModal] = useState(false);
  const [downloadType, setDownloadType] = useState(0);
  const navigate = useNavigate();
  const eventId = id!;
  const { t } = useTranslation();

  useEffect(() => {
    fetchStandsByEventId(eventId, true);
    fetchEventById(eventId);
    fetchStandSectorsByEventId(eventId, true);
    fetchProducts();
    fetchCustomers();
    fetchProductsByEvent(eventId, true);
    fetchCustomersByEvent(eventId, true);
    fetchStandTypes();
    setSelectedStandType('all')
  }, [eventId, fetchStandsByEventId, fetchEventById, fetchStandSectorsByEventId, fetchProducts, fetchProductsByEvent, fetchCustomers, fetchCustomersByEvent, fetchStandTypes]);

  const handleRowClick = (stand: Stand) => {
    navigate(`/stands/details/${stand.id}`);
  };

  const handleAddSector = () => {
    setSelectedSector(null);
    setSectorModalOpen(true);
  };

  const handleEditSector = (standSectorId: string) => {
    const standSector = sectorsByEventId?.find((s) => s.id === standSectorId);
    if (standSector) {
      setSelectedSector(standSector);
      setSectorModalOpen(true);
    }
  };

  const handleCloseSectorModal = () => {
    setSectorModalOpen(false);
    setSelectedSector(null);
    fetchStandSectorsByEventId(eventId, true);
  };

  const handleAddStand = (customerId: string) => {
    setSelectedCustomerId(customerId);
    setSelectedStand(null)
    setModalOpen(true);
  };

  const handleDeleteStand = async (standId: string) => {
    await deleteStand(standId);
    fetchStandsByEventId(eventId, true);
  };

  const handleDeleteSector = async (sectorId: string) => {
    await deleteStandSector(sectorId);
    fetchStandSectorsByEventId(eventId, true);
  };

  const handleAddProductSubmit = async (values: FormikValues) => {
    await addProductToEvent(eventId, values.productId);
    setProductModalOpen(false);
    fetchProductsByEvent(eventId, true);
  };

  const handleEditStand = (id: string, customerId: string) => {
    const stand = eventStands?.find((s) => s.id === id);
    setSelectedCustomerId(customerId);
    setSelectedStand(stand!);
    setModalOpen(true);
  };

  const handleDownload = useCallback((event: React.MouseEvent, customer: Customer) => {
    event.stopPropagation();
    setSelectedCustomer(customer);
    setSelectedStandType(null);
    setShowStandTypeModal(true);
  }, [setSelectedCustomer]);

  const confirmDownload = () => {
    if (downloadType === 1) {
      downloadInvoiceByCustomerId(selectedCustomer!, eventId, selectedStandType!);
    } else if (downloadType === 2) {
      downloadIssuedArticlesReportByCustomerId(selectedCustomer!, eventId, selectedStandType!);
    } else if (downloadType === 3) {
      downloadIssuedEventArticlesByCustomerReport(eventId, selectedStandType!);
    } else if (downloadType === 4) {
      downloadIssuedEventArticlesByProductReport(eventId, selectedStandType!);
    }

    setShowStandTypeModal(false);
  };

  if (isLoadingEvent) {
    return <Spin>Loading...</Spin>;
  }

  const expandedRowRender = (customer: Customer) => {
    const filteredStandsData = eventStands.filter(x => x.customer?.id === customer.id);

    return <>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => handleAddStand(customer.id!)}
      >
        {t('events.addStand')}
      </Button>

      <DataTable
        columns={[
          { header: t('general.name'), accessor: 'name', type: 'text' },
          { header: t('stand.sector'), accessor: 'sector.name', type: 'text' },
        ]}
        data={filteredStandsData}
        onDelete={handleDeleteStand}
        onEdit={(id) => handleEditStand(id, customer.id!)}
        onRowClick={handleRowClick}
        hidePagination
      />
    </>
  }

  const tabItems = [
    {
      key: '1',
      label: t('events.stands'),
      children: (
        <>
          <DataTable<Stand>
            columns={[
              { header: t('stand.customer'), accessor: 'customer.companyName', type: 'text' },
              { header: t('customer.standName'), accessor: 'name', type: 'text' },
              { header: t('stand.sector'), accessor: 'sector.name', type: 'text' },
            ]}
            data={eventStands}
            onRowClick={handleRowClick}
            enableFrontendFiltering={true}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('general.standOperators'),
      children: (
        <>
          <DataTable<Customer>
            columns={[
              { header: t('customer.companyName'), accessor: 'companyName', type: 'text' },
              { header: t('customer.email'), accessor: 'email', type: 'text' },
              { header: t('customer.phone'), accessor: 'phone', type: 'text' },
              {
                header: t('events.customerOnEvent'), accessor: '', type: 'actions', renderCell: (item: Customer) => {
                  return <Checkbox checked={Boolean(customersOfSelectedEvent?.find(x => x.id === item.id))}
                    onChange={async (e: CheckboxChangeEvent) => {
                      e.preventDefault();
                      if (e.target.checked) {
                        await addCustomerToEvent(eventId, item.id);
                      } else {
                        await deleteCustomerFromEvent(eventId, item.id);
                      }
                      fetchCustomersByEvent(eventId, true);
                    }} />
                }
              },
              {
                header: '', accessor: '', type: 'actions', renderCell: (item: Customer) => {
                  return customersOfSelectedEvent?.find(x => x.id === item.id) && <>
                    <Button
                      type="primary"
                      key="downloadInvoice"
                      icon={<DownloadOutlined />}
                      onClick={(event) => { setDownloadType(1); handleDownload(event, item); }}
                      style={{ margin: '5px 5px' }}
                    >
                      {t('events.downloadInvoice')}
                    </Button >

                    <Button
                      type="primary"
                      key="downloadIssuedArticlesReport"
                      icon={<DownloadOutlined />}
                      onClick={(event) => { setDownloadType(2); handleDownload(event, item); }}
                      style={{ margin: '5px 5px' }}>
                      {t('events.downloadIssuedArticlesReport')}
                    </Button>
                  </>
                }
              },
            ]}
            data={customers}
            defaultPageSize={100}
            enableFrontendFiltering={true}
            expandedRowRender={expandedRowRender}
          />
        </>
      ),
    },
    {
      key: '3',
      label: t('events.products'),
      children: (
        <>
          <DataTable<Product>
            columns={[
              { header: t('general.name'), accessor: 'name', type: 'text' },
              { header: t('products.shortName'), accessor: 'shortName', type: 'text' },
              {
                header: t('events.productOnEvent'), accessor: '', type: 'actions', renderCell: (item: Product) => {
                  return <Checkbox checked={Boolean(productsOfSelectedEvent?.find(x => x.id === item.id))}
                    onChange={async (e: CheckboxChangeEvent) => {
                      if (e.target.checked) {
                        await addProductToEvent(eventId, item.id);
                      } else {
                        await deleteProductFromEvent(eventId, item.id);
                      }
                      fetchProductsByEvent(eventId, true);
                    }} />
                }
              },
            ]}
            data={products}
            enableFrontendFiltering={true}
            defaultPageSize={100}
          />
        </>
      ),
    },
    {
      key: '4',
      label: t('events.sectors'),
      children: (
        <>
          <Button type="primary" onClick={handleAddSector} style={{ marginBottom: 16 }}>
            {t('events.addStandSector')}
          </Button>
          <DataTable<StandSector>
            columns={[
              { header: t('general.name'), accessor: 'name', type: 'text' },
              { header: t('general.notes'), accessor: 'notes', type: 'text' },
            ]}
            data={sectorsByEventId}
            onEdit={handleEditSector}
            onDelete={handleDeleteSector}
            enableFrontendFiltering={true}
          />
        </>
      ),
    },
  ];

  if (!selectedEvent) {
    return <div>{t('events.notFound')}</div>;
  }

  const handleOnCloseStandModal = () => {
    setModalOpen(false);
    setSelectedStand(null);
  }

  const handleEdit = () => {
    setSelectedItem(selectedEvent);
    setEditEventModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditEventModalOpen(false);
  };

  return (
    <>
      <Card
        title={`${selectedEvent?.name}`}
        style={{ marginBottom: '20px' }}
        extra={
          <Button type="primary" onClick={handleEdit}>
            {t('general.edit')}
          </Button>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t('events.status')}>{selectedEvent.status}</Descriptions.Item>
              <Descriptions.Item label={t('events.startDate')}>{Util.formatDate(selectedEvent.startDate)}</Descriptions.Item>
              <Descriptions.Item label={t('events.endDate')}>{Util.formatDate(selectedEvent.endDate)}</Descriptions.Item>
              <Descriptions.Item label={t('events.eventArea')}>{selectedEvent.eventArea}</Descriptions.Item>
              <Descriptions.Item label={t('events.description')}>{selectedEvent.description}</Descriptions.Item>
              <Descriptions.Item label={t('events.depositPrice')}>{selectedEvent.depositPrice} €</Descriptions.Item>
              <Descriptions.Item label={t('events.depositPriceDishes')}>{selectedEvent.depositPriceDishes} €</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t('customer.city')}>{selectedEvent.address?.city}</Descriptions.Item>
              <Descriptions.Item label={t('customer.street')}>{selectedEvent.address?.street}</Descriptions.Item>
              <Descriptions.Item label={t('customer.postalCode')}>{selectedEvent.address?.postalCode}</Descriptions.Item>
              <Descriptions.Item label={t('customer.state')}>{selectedEvent.address?.state}</Descriptions.Item>
              <Descriptions.Item label={t('customer.country')}>{selectedEvent.address?.country?.name}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Button
              type='primary'
              key="downloadIssuedArticlesReport"
              icon={<DownloadOutlined />}
              onClick={(event) => { setDownloadType(3); handleDownload(event, null as any); }}
              style={{ margin: '10px 5px' }}
            >
              {t('events.downloadIssuedArticlesReportByCustomer')}
            </Button>

            <Button
              type='primary'
              key="downloadIssuedArticlesReportByProduct"
              icon={<DownloadOutlined />}
              onClick={(event) => { setDownloadType(4); handleDownload(event, null as any); }}
              style={{ margin: '10px 5px' }}
            >
              {t('events.downloadIssuedArticlesReportByProduct')}
            </Button>
          </Col>
        </Row>
      </Card>

      <Tabs items={tabItems} defaultActiveKey="1" />
      <AddStandModal
        open={modalOpen}
        onClose={handleOnCloseStandModal}
        eventId={selectedEvent.id!}
        initialValues={selectedStand}
        customerId={selectedCustomerId}
      />
      <StandSectorFormModal
        open={sectorModalOpen}
        onClose={handleCloseSectorModal}
        initialValues={selectedSector}
        eventId={selectedEvent.id!}
      />
      <DataFormModal<{ productId: '' }>
        open={productModalOpen}
        onClose={() => setProductModalOpen(false)}
        title={t('events.addProduct')}
        initialValues={{ productId: '' }}
        onSubmit={handleAddProductSubmit}
        fields={[
          {
            label: t('events.product'),
            name: 'productId',
            type: 'select',
            placeholder: t('events.placeholderProduct'),
            options: products?.map((product: any) => ({
              value: product.id ?? '',
              label: product.name,
            })),
          },
        ]}
        FormComponent={DataForm as any}
      />
      <EventFormModal
        open={editEventModalOpen}
        onClose={handleCloseModal}
        initialValues={selectedEvent}
      />

      <Modal
        title={t('events.selectStandType')}
        open={showStandTypeModal}
        onOk={confirmDownload}
        onCancel={() => setShowStandTypeModal(false)}
      >
        <div>
          <h3>{t('stand.standTypes')}</h3>
          <Radio.Group
            value={selectedStandType || 'all'}
            onChange={(e) => setSelectedStandType(e.target.value)}
            defaultValue="all"
          >
            <Radio key="all" value={'all'}>{t('stand.all')}</Radio>
            {standTypes.map((standType) => (
              <Radio key={standType.id} value={standType.id}>
                {standType.name}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
};

export default EventDetails;
