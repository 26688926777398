import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import useCountryStore from '../../store/countryStore';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import { Country } from '../../models/countries/country';
import { useTranslation } from 'react-i18next';
import CountryFormModal from '../../components/Countries/CountryFormModal';

const CountriesPage: React.FC = () => {
  const { items: items, selectedItem, setSelectedItem, fetchItems, deleteItem } = useCountryStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleEdit = (countryId: string) => {
    const item = items.find((x) => x.id === countryId);
    if (item) {
      setSelectedItem(item);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const columns: DataTableColumn<Country>[] = [
    { header: t('countries.name'), accessor: 'name' },
    { header: t('countries.countryCode'), accessor: 'countryCode' },
    { header: t('countries.taxPercentage'), accessor: 'taxPercentage' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems])

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('countries.title')}</span>
            <Button type="primary" onClick={() => setModalOpen(true)}>
              {t('countries.addCountry')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
      <CountryFormModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialValues={selectedItem}
      />
    </>
  );
};

export default CountriesPage;
