import React, { useState } from 'react';
import { Form, Input, Button, Alert, Card } from 'antd';
import { useAuthStore } from '../store/authUserStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ForgotPassword: React.FC = (props) => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');
  const forgotPassword = useAuthStore((state) => state.forgotPassword);
  const error = useAuthStore((state) => state.error);
  const isLoading = useAuthStore((state) => state.isLoading);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (values: { email: string }) => {
    const { email } = values;
    await forgotPassword(email);
    if (!error) {
      navigate('/');
    }
  };

  return (
    <Card className="login-container">
      <h2>{t('general.forgotPassword')}</h2>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ email }}
        size='large'
      >
        <Form.Item
          label={t('general.email')}
          name="email"
          rules={[{ required: true, message: t('general.forgotPasswordEmailRequired') }]}
        >
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('general.forgotPassword')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ForgotPassword;
