import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import DataTable, { DataTableColumn } from '../components/DataTable/DataTable';
import UserFormModal from '../components/User/UserFormModal';
import useUserStore from '../store/userStore';
import { User } from '../models/users';
import { useTranslation } from 'react-i18next';

const Users: React.FC = () => {
  const { items, selectedItem, deleteItem, setSelectedItem, fetchItems } = useUserStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const columns: DataTableColumn<User>[] = [
    { header: t('user.useraname'), accessor: 'username' },
    { header: t('user.role'), accessor: 'role', type: 'enum' },
    { header: t('user.email'), accessor: 'email' }
  ];

  const handleEdit = (userId: string) => {
    const item = items.find((x) => x.id === userId);
    setSelectedItem(item || null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    fetchItems();
  }, [fetchItems])

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>Users</span>
            <Button
              type="primary"
              onClick={() => {
                setSelectedItem(null);
                setModalOpen(true);
              }}
            >
              {t('user.createUser')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
      {isModalOpen && (
        <UserFormModal open={isModalOpen} onClose={handleCloseModal} initialValues={selectedItem} />
      )}
    </>
  );
};

export default Users;
