import { create } from 'zustand';
import agent from '../api/agent';
import { EventModel } from '../models/events/events';
import { createBaseStore } from './util/createBaseStore';
import { EntityStore } from './util/interfaces/entityStore';
import { byKeyResults, OfflineStoreKeys } from '../offlinestore/offline.util';
import { Customer } from '../models/customers';


interface EventStore extends EntityStore<EventModel> {
    fetchEventsByStatus: (status: string) => Promise<EventModel[]>;
    eventsByStatus: EventModel[];
    downloadInvoiceByCustomerId: (customer: Customer, eventId: string, selectedStandType: string) => Promise<void>;
    downloadIssuedArticlesReportByCustomerId: (customer: Customer, eventId: string, selectedStandType: string) => Promise<void>;
    downloadIssuedEventArticlesByCustomerReport: (eventId: string, selectedStandType: string) => Promise<void>;
    downloadIssuedEventArticlesByProductReport: (eventId: string, selectedStandType: string) => Promise<void>;
}

const useEventStore = create<EventStore>((set, get) => ({
    ...createBaseStore<EventModel>(agent.Events, OfflineStoreKeys.EVENTS)(set, get, {} as any),
    eventsByStatus: [],
    fetchEventsByStatus: async (status: string) => {
        set({ isLoading: true, error: null });
        try {
            const events = await agent.Events.getAllByStatus(status.toUpperCase());
            const offlineEvents = await byKeyResults(events, 'status', status.toLowerCase(), get().storeKey) as any;
            set({ eventsByStatus: navigator.onLine ? events : offlineEvents });
            return navigator.onLine ? events : offlineEvents;
        } catch (error) {
            const offlineEvents = await byKeyResults([], 'status', status.toLowerCase(), get().storeKey) as any;
            set({ eventsByStatus: offlineEvents });
        } finally {
            set({ isLoading: false });
        }
    },
    downloadInvoiceByCustomerId: async (customer: Customer, eventId: string, selectedStandType: string) => {
        try {
            const response = await agent.Events.downloadInvoiceByCustomerId(eventId, customer.id, selectedStandType);
            const blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${customer.companyName!}-rechnung.pdf`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (err) {
            console.error("Error downloading PDF:", err);
        }
    },
    downloadIssuedArticlesReportByCustomerId: async (customer: Customer, eventId: string, selectedStandType: string) => {
        try {
            const response = await agent.Events.downloadIssuedArticlesReportByCustomerId(eventId, customer.id, selectedStandType);
            const blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${customer.companyName!}-ausgegebene-artikel.pdf`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (err) {
            console.error("Error downloading PDF:", err);
        }
    },
    downloadIssuedEventArticlesByCustomerReport: async (eventId: string, selectedStandType: string) => {
        try {
            const response = await agent.Events.downloadIssuedEventArticlesByCustomerReport(eventId, selectedStandType);
            const blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${get().selectedItem?.name}-ausgegebene-artikel.pdf`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (err) {
            console.error("Error downloading PDF:", err);
        }
    },
    async downloadIssuedEventArticlesByProductReport(eventId, selectedStandType) {
        try {
            const response = await agent.Events.downloadIssuedEventArticlesByProductReport(eventId, selectedStandType);
            const blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${get().selectedItem?.name}-ausgegebene-artikel.pdf`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (err) {
            console.error("Error downloading PDF:", err);
        }
    },
}));


export default useEventStore;
