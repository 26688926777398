import { create } from 'zustand';
import agent from '../api/agent';
import { createBaseStore } from './util/createBaseStore';
import { Country } from '../models/countries/country';
import { OfflineStoreKeys } from '../offlinestore/offline.util';

const useBaseCountryStore = create(createBaseStore<Country>(agent.Countries, OfflineStoreKeys.COUNTRIES));

export const useCountryStore = useBaseCountryStore;

export default useCountryStore;
