import { create } from 'zustand';
import agent from '../api/agent';
import { Product } from '../models/products/products';
import { createBaseStore } from './util/createBaseStore';
import { EntityStore } from './util/interfaces/entityStore';
import { EventModel } from '../models/events/events';
import { EventProducts } from '../models/eventProducts/eventProducts';
import { byKeyResults, offlineJoin, OfflineStoreKeys } from '../offlinestore/offline.util';
import { offlineStoreDb } from '../offlinestore/offlineStoreDb';

type ProductStore = EntityStore<Product> & {
    fetchProductsByEvent: (eventId: string, updateStore: boolean) => Promise<void>;
    addProductToEvent: (eventId: string, productId: string) => Promise<void>;
    deleteProductFromEvent: (eventId: string, productId: string) => Promise<void>;
    fetchEventsByProduct: (productId: string) => Promise<void>;
    productEvents?: EventModel[];
    productsOfSelectedEvent: Product[];
};


export const useProductStore = create<ProductStore>((set, get) => ({
    ...createBaseStore<Product>(agent.Products, OfflineStoreKeys.PRODUCTS)(set, get, {} as any),
    productsOfSelectedEvent: [],
    fetchProductsByEvent: async (eventId: string, updateStore: boolean) => {
        set({ isLoading: true, error: null });
        try {
            const products = await agent.Products.getByEvent(eventId);
            await offlineJoin(products, 'eventId', eventId, 'productId', offlineStoreDb.eventProducts);
            const offlineProducts = await byKeyResults([], 'eventId', eventId, OfflineStoreKeys.EVENT_PRODUCTS);
            if (updateStore) set({ productsOfSelectedEvent: offlineProducts.map((item: any) => (item as any).product!) || [] });
        } catch (error) {
            if (eventId) {
                const offlineProducts = await byKeyResults([], 'eventId', eventId, OfflineStoreKeys.EVENT_PRODUCTS);
                if (updateStore) set({ productsOfSelectedEvent: offlineProducts.map((item: any) => (item as any).product!) || [] });
            }
        } finally {
            set({ isLoading: false });
        }
    },
    addProductToEvent: async (eventId: string, productId: string) => {
        try {
            await agent.Products.addToEvent(eventId, productId);
        } catch (error) {
            // set({ error: i18next.t('products.messageNotifier.failedToAddToEvent') });
        } finally {
            await offlineStoreDb.eventProducts.add({ id: `${eventId}${productId}`, eventId: eventId, productId: productId } as EventProducts);
        }
    },
    deleteProductFromEvent: async (eventId: string, productId: string) => {
        try {
            await agent.Products.removeFromEvent(eventId, productId);
        } catch (error) {
            // set({ error: i18next.t('products.messageNotifier.failedToRemoveFromEvent') });
        } finally {
            await offlineStoreDb.eventProducts.where('productId').equals(productId).and(item => item.eventId == eventId).delete();
        }
    },
    fetchEventsByProduct: async (productId: string) => {
        set({ isLoading: true, error: null });
        try {
            const events = await agent.Products.getEventsByProduct(productId);
            await offlineJoin(events, 'productId', productId, 'eventId', offlineStoreDb.eventProducts, false);
            const offlineEvents = await byKeyResults([], 'productId', productId, OfflineStoreKeys.EVENT_PRODUCTS);
            set({ productEvents: navigator.onLine ? events : offlineEvents.map((item: any) => (item as any).event!) });
        } catch (error) {
            if (productId) {
                const offlineEvents = await byKeyResults([], 'productId', productId, OfflineStoreKeys.EVENT_PRODUCTS);
                set({ productEvents: offlineEvents.map((item: any) => (item as any).event!) });
            }
        } finally {
            set({ isLoading: false });
        }
    }
}));

export default useProductStore;
