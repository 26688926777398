import { create } from 'zustand';
import agent from '../api/agent';
import { User } from '../models/users';
import { notifyError, notifySuccess } from '../components/NotificationProvider';
import { jwtDecode } from 'jwt-decode';
import i18next from '../i18n'

interface AuthState {
    token: string | null;
    currentUser: User | null;
    isLoading: boolean;
    error: string | null;
    setToken: (token: string) => void;
    clearToken: () => void;
    login: (username: string, password: string) => Promise<void>;
    forgotPassword: (email: string) => Promise<void>;
    resetPassword: (resetToken: string, newPassword: string) => Promise<void>;
    setCurrentUser: (user: User | null) => void;
    setLoading: (loading: boolean) => void;
    setError: (error: string | null) => void;
}

export const useAuthStore = create<AuthState>((set, get) => {
    const token = localStorage.getItem('token');
    let currentUser: User | null = null;

    if (token) {
        try {
            currentUser = jwtDecode<User>(token);
        } catch (err) {
            console.error('Failed to decode token', err);
        }
    }

    return {
        token,
        currentUser,
        isLoading: false,
        error: null,
        setToken: (newToken: string) => {
            localStorage.setItem('token', newToken);
            if (navigator.onLine) {
                const decodedToken: User = jwtDecode(newToken);
                set({ token: newToken, currentUser: decodedToken });
            } else {
                set({ token: newToken, currentUser: null });

            }
        },
        clearToken: () => {
            if (navigator.onLine) {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                set({ token: null, currentUser: null });
            }
        },
        login: async (username: string, password: string) => {
            set({ isLoading: true, error: null });
            try {
                const { setToken, setCurrentUser } = get();
                let accessToken = null;
                let refreshToken = null;
                if (navigator.onLine) {
                    const response = await agent.Auth.login(username, password);
                    accessToken = response.access_token;
                    refreshToken = response.refresh_token;
                    localStorage.setItem('refresh_token', refreshToken);
                } else {
                    accessToken = "offlineModeToken";
                }
                
                setCurrentUser(jwtDecode<User>(accessToken));
                setToken(accessToken);
                notifySuccess(i18next.t('general.loginSuccessful'));
            } catch (err) {
                const errorMessage = i18next.t('general.loginInvalidUsernamePass');
                notifyError(errorMessage);
                set({ error: errorMessage });
            } finally {
                set({ isLoading: false });
            }
        },
        forgotPassword: async (email: string) => {
            set({ isLoading: true, error: null });
            try {
                if (navigator.onLine) {
                    await agent.Auth.forgotPassword(email);
                }
                notifySuccess(i18next.t('general.forgotPasswordSent'));
            } catch (err) {
                const errorMessage = i18next.t('general.forgotPasswordInvalidEmail');
                notifyError(errorMessage);
                set({ error: errorMessage });
            } finally {
                set({ isLoading: false });
            }
        },
        resetPassword: async (resetToken: string, newPassword: string) => {
            set({ isLoading: true, error: null });
            try {
                if (navigator.onLine) {
                    await agent.Auth.resetPassword(resetToken, newPassword);
                }
                notifySuccess(i18next.t('general.resetPasswordCompleted'));
            } catch (err) {
                const errorMessage = i18next.t('general.resetPasswordInvalidToken');
                notifyError(errorMessage);
                set({ error: errorMessage });
            } finally {
                set({ isLoading: false });
            }
        },
        setCurrentUser: (user: User | null) => set({ currentUser: user }),
        setLoading: (loading: boolean) => set({ isLoading: loading }),
        setError: (error: string | null) => set({ error }),
    };
});
