import { create } from 'zustand';
import agent from '../api/agent';
import { createBaseStore } from './util/createBaseStore';
import { StandType } from '../models/standTypes/StandType';
import { OfflineStoreKeys } from '../offlinestore/offline.util';

const useBaseStandTypeStore = create(createBaseStore<StandType>(agent.StandTypes, OfflineStoreKeys.STAND_TYPES));

export const useStandTypeStore = useBaseStandTypeStore;

export default useStandTypeStore;
