import { create } from 'zustand';
import agent from '../api/agent';
import { createBaseStore } from './util/createBaseStore';
import { StandSector } from '../models/standSectors/standSector';
import { EntityStore } from './util/interfaces/entityStore';
import { byKeyResults, OfflineStoreKeys } from '../offlinestore/offline.util';

interface StandSectorStore extends EntityStore<StandSector> {
    fetchStandSectorsByEventId: (eventId: string, updateStore: boolean) => Promise<void>;
    sectorsByEventId: StandSector[];
}

export const useStandSectorStore = create<StandSectorStore>((set, get) => ({
    ...createBaseStore<StandSector>(agent.StandSectors as any, OfflineStoreKeys.STAND_SECTORS)(set, get, {} as any),
    sectorsByEventId: [],
    fetchStandSectorsByEventId: async (eventId: string, updateStore: boolean) => {
        set({ isLoading: true, error: null });
        try {
            const standSectors = await agent.StandSectors.getAll(eventId!);
            const offlineStandSectors = await byKeyResults(standSectors, 'eventId', eventId, get().storeKey);
            if (updateStore) set({ sectorsByEventId: navigator.onLine ? standSectors : offlineStandSectors });
        } catch (error) {
            if (eventId) {
                const offlineStandSectors = await byKeyResults([], 'eventId', eventId, get().storeKey);
                if (updateStore) set({ sectorsByEventId: offlineStandSectors });
            }
        } finally {
            set({ isLoading: false });
        }
    },
}));

export default useStandSectorStore;
