import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import * as Yup from 'yup';
import DataForm from '../DataForm/DataForm';
import useUserStore from '../../store/userStore';
import { notifyError } from '../NotificationProvider';
import { User } from '../../models/users';
const { v4: uuidv4 } = require('uuid');
import { useTranslation } from 'react-i18next';

interface UserFormModalProps {
  open: boolean;
  onClose: () => void;
  initialValues: User | null;
}

const UserFormModal: React.FC<UserFormModalProps> = ({ open, onClose, initialValues }) => {
  const { createItem, updateItem } = useUserStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsEditMode(!!initialValues);
  }, [initialValues]);

  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      if (isEditMode && initialValues) {
        await updateItem(initialValues.id, values);
      } else {
        await createItem(values);
      }
      resetForm();
      onClose();
    } catch (error) {
      notifyError(t('user.messageNotifier.errorSavingUser'));
    } finally {
      setSubmitting(false);
    }
  };

  const defaultValues = {
    id: initialValues?.id || uuidv4(),
    username: initialValues?.username || '',
    password: initialValues?.password || '',
    role: initialValues?.role || 'admin',
    email: initialValues?.email || ''
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(t('user.messageNotifier.usernameRequired')),
    password: Yup.string().required(t('user.messageNotifier.passwordRequired')),
    role: Yup.string().oneOf(['admin', 'customer'], t('user.messageNotifier.invalidRole')).required(t('user.messageNotifier.roleRequired')),
    email: Yup.string().email(t('user.messageNotifier.invalidEmail')).required(t('user.messageNotifier.emailRequired')),
  });

  return (
    <Modal
      title={isEditMode ? t('user.editUser') : t('user.createUser')}
      open={open}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <DataForm
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onCancel={onClose}
        fields={isEditMode ?
          [
            { label: t('user.useraname'), name: 'username', type: 'text', placeholder: t('user.placeholderUsername') },
            { label: t('user.email'), name: 'email', type: 'text', placeholder: t('user.placeholderEmail') },
            {
              label: t('user.role'),
              name: 'role',
              type: 'select',
              placeholder: t('user.placeholderRole'),
              options: [
                { key: 'admin', label: t('user.admin'), value: 'admin' }
              ],
            }
          ] :
          [
            { label: t('user.useraname'), name: 'username', type: 'text', placeholder: t('user.placeholderUsername') },
            {
              label: t('general.password'),
              name: 'password',
              type: 'password',
              placeholder: t('general.placeholderPassword'),
            },
            { label: t('user.email'), name: 'email', type: 'text', placeholder: t('user.placeholderEmail') },
            {
              label: t('user.role'),
              name: 'role',
              type: 'select',
              placeholder: t('user.placeholderRole'),
              options: [
                { key: 'admin', label: t('user.admin'), value: 'admin' }
              ],
            }
          ]
        }
      />
    </Modal>
  );
};

export default UserFormModal;
