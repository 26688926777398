export interface User {
    id: string;
    username: string;
    role: string;
    password?: string;
    email?: string;
}

export enum UserRole {
    ADMIN = 'admin',
    CUSTOMER = 'customer',
}