import React, { memo, useEffect, useState } from "react";
import { DataTableColumn } from "./DataTable";
import { Input } from "antd";

interface ColumnSearchInputProps {
    column: DataTableColumn<any>;
    filters: Record<string, string | number>;
    onChange: (columnKey: string, value: any) => void;
    visible: boolean;
}

export const ColumnSearchInput = memo(({ column, filters, visible, onChange }: ColumnSearchInputProps) => {
    const [value, setValue] = useState<string | number>(filters[column.accessor as string] ?? '');

    useEffect(() => {
        setValue(filters[column.accessor as string] ?? '');
    }, [filters, column.accessor]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = column.type === 'number' ? e.target.value !== '' ? Number(e.target.value) : '' : e.target.value;
        setValue(newValue);
        onChange(column.accessor as string, newValue);
    };

    return (
        <Input
            placeholder={`Search ${column.header}`}
            value={value}
            type={column.type === 'number' ? 'number' : 'text'}
            onChange={handleChange}
            style={{ visibility: visible ? 'visible' : 'hidden' }}
        />
    );
});

ColumnSearchInput.displayName = 'ColumnSearchInput';
