import React from 'react';
import { Select } from 'antd';

const CustomSelect: React.FC<any> = ({ field, form, options, ...props }) => {
  const handleChange = (value: any) => {
    form.setFieldValue(field.name, value);
    const selectedOption = options.find((option: any) => option.value === value);

    if (selectedOption && selectedOption.onChange) {
      selectedOption.onChange(value, form.setFieldValue);
    }
  };

  return (
    <Select
      {...field}
      {...props}
      value={field.value}
      onChange={handleChange}
      options={options.map((option: { value: any; label: string; key?: string }) => ({
        key: option.key || option.value,
        label: option.label,
        value: option.value,
      }))}
    />
  );
};

export default CustomSelect;
