import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Row, Col, Button, Tabs } from 'antd';
import useCustomerStore from '../../store/customerStore';
import useOrderStore from '../../store/orderStore';
import { Stand } from '../../models/stands/stand';
import { Order } from '../../models/orders/order';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import CustomerFormModal from '../../components/Customers/CustomerFormModal';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/authUserStore';
import { UserRole } from '../../models/users';

const CustomerDetailsScreen: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { customerStands, fetchStandsByCustomerId, selectedItem, setSelectedItem, fetchItemById, fetchCustomerByUserId } = useCustomerStore();
  const { selectedCustomerOrders, fetchOrdersByCustomerId } = useOrderStore();
  const { currentUser } = useAuthStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedItem || selectedItem.id !== customerId) {
      if (customerId) {
        fetchItemById(customerId!);
      } else if (!customerId && !selectedItem) {
        fetchCustomerByUserId(currentUser?.id!)
      }
    }
  }, [customerId, selectedItem, fetchItemById, fetchCustomerByUserId]);

  useEffect(() => {
    if (selectedItem) {
      customerId && fetchStandsByCustomerId(selectedItem.id!);
      fetchOrdersByCustomerId(selectedItem.id!);
    }
  }, [selectedItem, fetchStandsByCustomerId, fetchOrdersByCustomerId]);

  const standColumns: DataTableColumn<Stand>[] = [
    { header: t('customer.eventName'), accessor: 'event.name' },
    { header: t('customer.standName'), accessor: 'name' },
    { header: t('general.email'), accessor: 'email' },
  ];

  const orderColumns: DataTableColumn<Order>[] = [
    { header: t('customer.orderNumber'), accessor: 'orderNumber' },
    { header: t('general.event'), accessor: 'stand.event.name' },
    { header: t('general.date'), accessor: 'date', type: 'date' },
    { header: t('customer.totalAmount'), accessor: 'totalPrice' },
  ];

  const handleRowClick = (stand: Stand) => {
    navigate(`/stands/details/${stand.id}`);
  };

  const handleOrderRowClick = (order: Order) => {
    navigate(`/orders/details/${order.id}`);
  };

  const handleEdit = () => {
    setSelectedItem(selectedItem);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const tabItems = [
    {
      key: '1',
      label: t('customer.orders'),
      children: (
        <DataTable
          columns={orderColumns}
          data={selectedCustomerOrders}
          onRowClick={handleOrderRowClick}
          enableFrontendFiltering
        />
      ),
    },
    ...(currentUser?.role === UserRole.ADMIN ? [
      {
        key: '2',
        label: t('customer.stands'),
        children: (
          <DataTable
            columns={standColumns}
            data={customerStands}
            onRowClick={handleRowClick}
            enableFrontendFiltering
          />
        ),
      }
    ] : []),
  ];

  return (
    <div style={{ padding: '0px 20px' }}>
      <Card
        title={`${selectedItem?.companyName}`}
        style={{ marginBottom: '20px' }}
        extra={
          currentUser?.role === UserRole.ADMIN && <Button type="primary" onClick={handleEdit}>
            {t('customer.edit')}
          </Button>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t('customer.username')}>{selectedItem?.username}</Descriptions.Item>
              <Descriptions.Item label={t('customer.firstName')}>{selectedItem?.firstName}</Descriptions.Item>
              <Descriptions.Item label={t('customer.lastName')}>{selectedItem?.lastName}</Descriptions.Item>
              <Descriptions.Item label={t('customer.companyName')}>{selectedItem?.companyName}</Descriptions.Item>
              <Descriptions.Item label={t('customer.phone')}>{selectedItem?.phone}</Descriptions.Item>
              <Descriptions.Item label={t('customer.email')}>{selectedItem?.email}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t('customer.street')}>{selectedItem?.address?.street}</Descriptions.Item>
              <Descriptions.Item label={t('customer.city')}>{selectedItem?.address?.city}</Descriptions.Item>
              <Descriptions.Item label={t('customer.state')}>{selectedItem?.address?.state}</Descriptions.Item>
              <Descriptions.Item label={t('customer.postalCode')}>{selectedItem?.address?.postalCode}</Descriptions.Item>
              <Descriptions.Item label={t('customer.country')}>{selectedItem?.address?.country?.name}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>

      <Tabs
        defaultActiveKey="1"
        activeKey={selectedTab}
        onChange={(key) => setSelectedTab(key)}
        items={tabItems}
      />

      <CustomerFormModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialValues={selectedItem}
      />
    </div>
  );
};

export default CustomerDetailsScreen;
