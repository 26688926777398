import React, { useState, useEffect } from 'react';
import { FormikValues } from 'formik';
import DataFormModal from '../DataForm/DataFormModal';
import DataForm from '../DataForm/DataForm';
import { StandSector } from '../../models/standSectors/standSector';
import useStandSectorStore from '../../store/standSectoreStore';
import { StandSectorFormModalProps } from '../../models/standSectors/standSectorFormModalProps';
import useEventStore from '../../store/eventStore';
const { v4: uuidv4 } = require('uuid');
import { useTranslation } from 'react-i18next';
import useStandStore from '../../store/standStore';

const StandSectorFormModal: React.FC<StandSectorFormModalProps> = ({
  open,
  onClose,
  initialValues,
  eventId,
}) => {
  const { createItem, updateItem } = useStandSectorStore();
  const { fetchItemById: fetchEventById } = useEventStore();
  const { fetchStandsByEventId } = useStandStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [initialValues]);

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    try {
      const standSectorData: StandSector = { ...values, eventId, id: isEditMode ? values.id : uuidv4() };

      if (isEditMode && initialValues) {
        await updateItem(values.id!, standSectorData);
      } else {
        await createItem(standSectorData);
      }

      fetchEventById(eventId);
      fetchStandsByEventId(eventId, true);
      resetForm();
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DataFormModal<StandSector>
      open={open}
      title={isEditMode ? t('stand.editStandSector') : t('stand.createStandSector')}
      onClose={onClose}
      initialValues={
        initialValues || {
          id: uuidv4(),
          name: '',
          notes: '',
          eventId,
        }
      }
      onSubmit={handleSubmit}
      fields={[
        { label: t('general.name'), name: 'name', type: 'text', placeholder: t('stand.placeholderName') },
        {
          label: t('general.notes'),
          name: 'notes',
          type: 'textarea',
          placeholder: t('general.placeholderEnterNotes'),
        },
      ]}
      FormComponent={DataForm as any}
    />
  );
};

export default StandSectorFormModal;
