import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthStore } from '../store/authUserStore'

interface ProtectedRouteProps {
    component: React.ComponentType<any>
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    component: Component,
}) => {
    const token = useAuthStore((state) => state.token)
    const location = useLocation()

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return <Component />
}

export default ProtectedRoute
