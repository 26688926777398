import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import DataForm from '../DataForm/DataForm';
import useCustomerStore from '../../store/customerStore';
import { Customer } from '../../models/customers';
import { FormikValues } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useCountryStore from '../../store/countryStore';

const { v4: uuidv4 } = require('uuid');

interface CustomerFormModalProps {
  open: boolean;
  onClose: () => void;
  initialValues: Customer | null;
}

const CustomerFormModal: React.FC<CustomerFormModalProps> = ({ open, onClose, initialValues }) => {
  const { fetchItems: fetchCountries, items: countries } = useCountryStore();
  const { createItem, updateItem } = useCustomerStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (initialValues) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [initialValues]);

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    try {
      if (isEditMode && initialValues) {
        await updateItem(values.id!, { ...values });
      } else {
        await createItem({ id: uuidv4(), ...values } as Customer);
      }
      resetForm();
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={isEditMode ? t('customer.edit') : t('customer.create')}
      open={open}
      onCancel={onClose}
      footer={null}
      width={"80%"}
    >
      <DataForm
        initialValues={
          initialValues || {
            firstName: '',
            lastName: '',
            companyName: '',
            phone: '',
            email: '',
            address: {
              street: '',
              city: '',
              state: '',
              postalCode: '',
              countryId: ''
            },
            notes: ''
          }
        }
        numberOfColumns={3}
        validationSchema={Yup.object({
          username: Yup.string().nullable(),
          firstName: Yup.string().required(t('customer.requiredFirstName')),
          lastName: Yup.string().required(t('customer.requiredLastName')),
          companyName: Yup.string().required(t('customer.requiredCompany')),
          phone: Yup.string().required(t('customer.requiredPhone')),
          email: Yup.string().email(t('customer.invalidEmail')).required(t('customer.requiredEmail')),
          address: Yup.object({
            street: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            postalCode: Yup.string(),
          }),
        })}
        onSubmit={handleSubmit}
        onCancel={onClose}
        fields={[
          { label: t('customer.username'), name: 'username', type: 'text', placeholder: t('customer.placeholderUsername') },
          { label: t('customer.firstName'), name: 'firstName', type: 'text', placeholder: t('customer.placeholderFirstName') },
          { label: t('customer.lastName'), name: 'lastName', type: 'text', placeholder: t('customer.placeholderLastName') },
          {
            label: t('customer.companyName'),
            name: 'companyName',
            type: 'text',
            placeholder: t('customer.placeholderCompanyName'),
          },
          { label: t('customer.phone'), name: 'phone', type: 'text', placeholder: t('customer.placeholderPhone') },
          { label: t('customer.email'), name: 'email', type: 'email', placeholder: t('customer.placeholderEmail') },
          { label: t('customer.street'), name: 'address.street', type: 'text', placeholder: t('customer.placeholderStreet') },
          { label: t('customer.city'), name: 'address.city', type: 'text', placeholder: t('customer.placeholderCity') },
          { label: t('customer.state'), name: 'address.state', type: 'text', placeholder: t('customer.placeholderState') },
          {
            label: t('customer.postalCode'),
            name: 'address.postalCode',
            type: 'text',
            placeholder: t('customer.placeholderPostalCode'),
          },
          {
            label: t('customer.country'),
            name: 'address.countryId',
            type: 'select',
            placeholder: t('customer.country'),
            options: countries.map((x) => {
              return { key: x.id, label: x.name, value: x.id };
            }),
          },
          {
            label: t('customer.notes'),
            name: 'notes',
            type: 'textarea',
            placeholder: t('customer.notes'),
          }
        ]}
      />
    </Modal>
  );
};

export default CustomerFormModal;
