import React from 'react';
import { useAuthStore } from '../store/authUserStore';
import { Layout, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Dashboard: React.FC = () => {
  const currentUser = useAuthStore((state) => state.currentUser);
  const { t } = useTranslation();

  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <Title level={2}>{t('dashboard.title')}</Title>
        {currentUser ? (
          <div>
            <Paragraph>{t('dashboard.welcome')}, {currentUser.username}!</Paragraph>
            <Paragraph>{t('dashboard.role')}: {currentUser.role}</Paragraph>
          </div>
        ) : (
          <Paragraph>{t('dashboard.noUserAvailable')}</Paragraph>
        )}
      </Content>
    </Layout>
  );
};

export default Dashboard;
