import React, { useState } from 'react';
import { Form, Input, Button, Alert, Card } from 'antd';
import { useAuthStore } from '../store/authUserStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [resetToken, setResetToken] = useState(id);
  const [newPassword, setNewPassword] = useState('');
  const resetPassword = useAuthStore((state) => state.resetPassword);
  const error = useAuthStore((state) => state.error);
  const isLoading = useAuthStore((state) => state.isLoading);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (values: { resetToken: string, newPassword: string }) => {
    const { resetToken, newPassword } = values;
    await resetPassword(resetToken, newPassword);
    if (!error) {
      navigate('/');
    }
  };

  return (
    <Card className="login-container">
      <h2>{t('general.resetPassword')}</h2>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ resetToken, newPassword }}
        size='large'
      >
        <Form.Item
          label={t('general.resetToken')}
          name="resetToken"
          rules={[{ required: true, message: t('general.resetPasswordResetTokenRequired') }]}
        >
          <Input value={resetToken} onChange={(e) => setResetToken(e.target.value)} />
        </Form.Item>
        <Form.Item
          label={t('general.newPassword')}
          name="newPassword"
          rules={[{ required: true, message: t('general.resetPasswordNewPasswordRequired') }]}
        >
          <Input.Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('general.resetPassword')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ResetPassword;
