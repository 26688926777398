import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import ProductFormModal from '../../components/Products/ProductFormModal';
import { Product } from '../../models/products/products';
import { useNavigate } from 'react-router-dom';
import { useProductStore } from '../../store/productStore';
import { useTranslation } from 'react-i18next';

const Products: React.FC = () => {
  const { items: products, fetchItems, deleteItem } = useProductStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEdit = (productId: string) => {
    const item = products.find((x) => x.id === productId);
    setSelectedProduct(item || null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  const columns: DataTableColumn<Product>[] = [
    { header: t('products.name'), accessor: 'name' },
    { header: t('products.shortName'), accessor: 'shortName' },
    { header: t('products.type'), accessor: 'type.name' },
    { header: t('products.serviceCostPrice'), accessor: 'serviceCostPrice', type: 'currency' },
    { header: t('products.creditNotePrice'), accessor: 'creditNotePrice', type: 'currency' },
    { header: t('products.quantityPerBox'), accessor: 'quantityPerBox', type: 'number' },
    { header: t('products.notes'), accessor: 'notes' },
  ];

  const handleRowClick = (id: string) => {
    navigate(`/products/${id}`);
  };

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('products.title')}</span>
            <Button type="primary" onClick={() => setModalOpen(true)}>
              {t('products.createProduct')}
            </Button>
            <ProductFormModal
              open={isModalOpen}
              onClose={handleCloseModal}
              initialValues={selectedProduct}
            />
          </>
        }
      >
        <DataTable
          columns={columns}
          data={products}
          onDelete={deleteItem}
          onEdit={(product) => handleEdit(product)}
          onRowClick={(x) => handleRowClick(x.id!)}
          enableFrontendFiltering
        />
      </Card>
    </>
  );
};

export default Products;
