import React, { useEffect } from 'react';
import { Formik, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Form, Input, Select, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Product } from '../../models/products/products';

interface OrderItemFormProps {
  initialValues: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  products: Product[];
}

const { Item: FormItem } = Form;
const { Option } = Select;

const OrderItemForm: React.FC<OrderItemFormProps> = ({ initialValues, onSubmit, onCancel, products }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    productId: Yup.string().required(t('order.messageNotifier.productRequired')),
  });

  const handleProductChange = (value: any, setFieldValue: (field: string, value: any) => void) => {
    const selectedProduct = products.find(product => product.id === value);
    if (selectedProduct) {
      setFieldValue('productId', selectedProduct.id);
      setFieldValue('issuedItems.unitsPerBox', selectedProduct.quantityPerBox);

      if (initialValues?.id) {
        setFieldValue('usedItems.unitsPerBox', selectedProduct.quantityPerBox);
        setFieldValue('unusedItems.unitsPerBox', selectedProduct.quantityPerBox);
        setFieldValue('cleanItems.unitsPerBox', selectedProduct.quantityPerBox);
      }
    }
  };

  const calculateTotal = (values: any, section: string) => {
    const sectionData = values[section];

    if (!sectionData) {
      return 0;
    }

    const { multiplier = 0, unitsPerBox = 0, singleUnits = 0 } = sectionData;
    return multiplier * unitsPerBox + singleUnits;
  };

  const UpdateUnitsPerBox = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (initialValues?.productId) {
        const selectedProduct = products.find(product => product.id === initialValues.productId);
        if (selectedProduct) {
          setFieldValue('issuedItems.unitsPerBox', selectedProduct.quantityPerBox);
          setFieldValue('usedItems.unitsPerBox', selectedProduct.quantityPerBox);
          setFieldValue('unusedItems.unitsPerBox', selectedProduct.quantityPerBox);
          setFieldValue('cleanItems.unitsPerBox', selectedProduct.quantityPerBox);
        }
      }
    }, [setFieldValue]);

    return null; // No UI, just need the effect
  };


  return (
    <Formik
      initialValues={{
        ...initialValues,
        productId: initialValues?.productId || "",
        issuedItems: {
          multiplier: initialValues?.issuedItems?.multiplier || 0,
          unitsPerBox: initialValues?.issuedItems?.unitsPerBox || 0,
          singleUnits: initialValues?.issuedItems?.singleUnits || 0,
          totalQuantity: initialValues?.issuedItems?.totalQuantity
        },
        usedItems: {
          multiplier: initialValues?.usedItems?.multiplier || 0,
          unitsPerBox: initialValues?.usedItems?.unitsPerBox || 0,
          singleUnits: initialValues?.usedItems?.singleUnits || 0,
          totalQuantity: initialValues?.issuedItems?.totalQuantity
        },
        unusedItems: {
          multiplier: initialValues?.unusedItems?.multiplier || 0,
          unitsPerBox: initialValues?.unusedItems?.unitsPerBox || 0,
          singleUnits: initialValues?.unusedItems?.singleUnits || 0,
          totalQuantity: initialValues?.unusedItems?.totalQuantity
        },
        cleanItems: {
          multiplier: initialValues?.cleantems?.multiplier || 0,
          unitsPerBox: initialValues?.cleantems?.unitsPerBox || 0,
          singleUnits: initialValues?.cleantems?.singleUnits || 0,
          totalQuantity: initialValues?.cleantems?.totalQuantity
        },
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        values.issuedItems.totalQuantity = calculateTotal(values, 'issuedItems');
        values.usedItems.totalQuantity = calculateTotal(values, 'usedItems');
        values.unusedItems.totalQuantity = calculateTotal(values, 'unusedItems');
        values.cleanItems.totalQuantity = calculateTotal(values, 'cleanItems');
        onSubmit(values);
        resetForm();
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting, touched, errors, resetForm }) => (
        <Form layout="vertical" onFinish={handleSubmit as any}>
          <UpdateUnitsPerBox />
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label={t('order.product')}
                validateStatus={touched.productId && errors.productId ? 'error' : ''}
                help={touched.productId && typeof errors.productId === 'string' ? errors.productId : undefined}
              >
                <Select
                  id={"productId"}
                  value={values.productId}
                  placeholder={t('order.placeholderSelectProduct')}
                  onChange={(value) => handleProductChange(value, setFieldValue)}
                >
                  {products.map((product) => (
                    <Option key={product.id} value={product.id}>
                      {product.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>

          {values.productId && (
            <>
              <h3>{t('order.issuedOrderItems')}</h3>
              <Row gutter={16}>
                <Col span={6}>
                  <FormItem label={t('order.multiplier')}>
                    <Field as={Input} name="issuedItems.multiplier" type="number" />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t('order.unitsPerBox')}>
                    <Field as={Input} name="issuedItems.unitsPerBox" type="number" readOnly />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t('order.singleUnits')}>
                    <Field as={Input} name="issuedItems.singleUnits" type="number" />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t('order.totalQuantity')}>
                    <Input value={calculateTotal(values, 'issuedItems')} name="issuedItems.totalQuantity" type="number" readOnly />
                  </FormItem>
                </Col>
              </Row>
            </>
          )}

          {initialValues.id && <>
            <h3>{t('order.usedOrderItems')}</h3>
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label={t('order.multiplier')}>
                  <Field as={Input} name="usedItems.multiplier" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.unitsPerBox')}>
                  <Field as={Input} name="usedItems.unitsPerBox" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.singleUnits')}>
                  <Field as={Input} name="usedItems.singleUnits" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.totalQuantity')}>
                  <Input value={calculateTotal(values, 'usedItems')} name="usedItems.totalQuantity" type="number" readOnly />
                </FormItem>
              </Col>
            </Row>

            <h3>{t('order.unusedOrderItems')}</h3>
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label={t('order.multiplier')}>
                  <Field as={Input} name="unusedItems.multiplier" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.unitsPerBox')}>
                  <Field as={Input} name="unusedItems.unitsPerBox" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.singleUnits')}>
                  <Field as={Input} name="unusedItems.singleUnits" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.totalQuantity')}>
                  <Input value={calculateTotal(values, 'unusedItems')} name="unusedItems.totalQuantity" type="number" readOnly />
                </FormItem>
              </Col>
            </Row>

            <h3>{t('order.cleanOrderItems')}</h3>
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label={t('order.multiplier')}>
                  <Field as={Input} name="cleanItems.multiplier" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.unitsPerBox')}>
                  <Field as={Input} name="cleanItems.unitsPerBox" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.singleUnits')}>
                  <Field as={Input} name="cleanItems.singleUnits" type="number" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label={t('order.totalQuantity')}>
                  <Input value={calculateTotal(values, 'cleanItems')} name="cleanItems.totalQuantity" type="number" readOnly />
                </FormItem>
              </Col>
            </Row>
          </>}

          <FormItem style={{ textAlign: 'right' }}>
            <Button
              type="default"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              style={{ marginRight: '10px' }}
            >
              {t('general.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {t('general.submit')}
            </Button>
          </FormItem>
        </Form>
      )}
    </Formik>
  );
};

export default OrderItemForm;
