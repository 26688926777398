export const paginationLocales: any = {
    en: {
        items_per_page: '/ page',
        jump_to: 'Jump to',
        jump_to_confirm: 'Confirm',
        page: '',
    },
    de: {
        items_per_page: '/ Seite',
        jump_to: 'Gehe zu',
        jump_to_confirm: 'Bestätigen',
        page: '',
    },
};