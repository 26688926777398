import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Card } from 'antd';
import { useAuthStore } from '../store/authUserStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../models/users';

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, currentUser, isLoading } = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (values: { username: string; password: string }) => {
    const { username, password } = values;
    await login(username, password);
  };


  useEffect(() => {
    if (!isLoading && currentUser) {
      if (currentUser.role === UserRole.CUSTOMER) {
        navigate('/my-profile/');
      } else {
        navigate('/events');
      }
    }
  }, [currentUser, isLoading, error, navigate]);

  return (
    <Card className="login-container">
      <h2>{t('general.login')}</h2>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ username, password }}
        size='large'
      >
        <Form.Item
          label={t('general.username')}
          name="username"
          rules={[{ required: true, message: t('general.wrongUsername') }]}
        >
          <Input value={username} onChange={(e) => setUsername(e.target.value)} />
        </Form.Item>
        <Form.Item
          label={t('general.password')}
          name="password"
          rules={[{ required: true, message: t('general.wrongPassword') }]}
        >
          <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert message={error} type="error" showIcon />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('general.login')}
          </Button>
          <Button type="primary" onClick={_ => navigate('/forgot-password')} style={{ margin: '20px' }}>
            {t('general.forgotPassword')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Login;
