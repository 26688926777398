import { create } from 'zustand';
import agent from '../api/agent';
import { createBaseStore } from './util/createBaseStore';
import { ProductType } from '../models/productTypes/productType';
import { OfflineStoreKeys } from '../offlinestore/offline.util';

const useBaseProductTypeStore = create(createBaseStore<ProductType>(agent.ProductTypes, OfflineStoreKeys.PRODUCT_TYPES));

export const useProductTypeStore = useBaseProductTypeStore;

export default useProductTypeStore;
