import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import useProductTypeStore from '../../store/productTypeStore';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import ProductTypeFormModal from '../../components/ProductTypes/ProductTypeFormModal';
import { ProductType } from '../../models/productTypes/productType';
import { useTranslation } from 'react-i18next';

const ProductTypesPage: React.FC = () => {
  const { items: items, selectedItem, setSelectedItem, fetchItems, deleteItem } = useProductTypeStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleEdit = (productTypeId: string) => {
    const item = items.find((x) => x.id === productTypeId);
    if (item) {
      setSelectedItem(item);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const columns: DataTableColumn<ProductType>[] = [
    { header: t('productTypes.name'), accessor: 'name' },
    { header: t('productTypes.notes'), accessor: 'notes' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems])

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('productTypes.title')}</span>
            <Button type="primary" onClick={() => setModalOpen(true)}>
              {t('productTypes.addType')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
      <ProductTypeFormModal
        open={isModalOpen}
        onClose={handleCloseModal}
        initialValues={selectedItem}
      />
    </>
  );
};

export default ProductTypesPage;
