import { Card } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    return (
        <Card>
            <h1>{t('sidebar.home')} </h1>
        </Card>
    )
}

export default Home
