import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Button, Card, Modal, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useOrderStore from '../../store/orderStore';
import { NewOrder } from '../../models/orders/newOrder';
import { OrderItem } from '../../models/orderItems/orderItem';
import { Order } from '../../models/orders/order';
import { notifyError } from '../../components/NotificationProvider';
import NewOrderForm from './NewOrderForm';
import OrderItemForm from './OrderItemForm';
import { FormikValues } from 'formik';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import { OrderStatus } from '../../models/orders/orderStatus';
import useStandStore from '../../store/standStore';
import { useProductStore } from '../../store/productStore';
import useOrderItemStore from '../../store/orderItemStore';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/authUserStore';

const { v4: uuidv4 } = require('uuid');

const StandDetails: React.FC = () => {
  const { Title, Paragraph } = Typography;
  const { id } = useParams<{ id: string }>();
  const { selectedItem: selectedStand, fetchItemById: fetchStandById } = useStandStore();
  const { productsOfSelectedEvent, fetchProductsByEvent } = useProductStore();
  const { selectedStandOrders, createItem: createOrder, deleteItem: deleteOrder, downloadPDFByBillType, fetchOrdersByStandId, updateItem: updateOrder } = useOrderStore();
  const { currentUser } = useAuthStore();
  const { items: orderItemsByEvent, selectedItem: selectedOrderItem, createItem: createOrderItem, deleteItem: deleteOrderItem, updateItem: updateOrderItem, fetchOrderItemsByEventId, setSelectedItem: setSelectedOrderItem } = useOrderItemStore();
  const [newOrder, setNewOrder] = useState<NewOrder>(() => ({
    id: uuidv4(),
    standId: id || '',
    orderNumber: uuidv4().substring(0, 8).toUpperCase(),
    totalPrice: 0,
    customerId: selectedStand?.customerId || '',
    status: OrderStatus.Pending,
    eventId: selectedStand?.eventId || ''
  }));
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
  const [isOrderItemModalVisible, setIsOrderItemModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchStandById(id!);
    fetchOrdersByStandId(id!);
  }, [id, fetchOrdersByStandId, fetchStandById]);

  useEffect(() => {
    if (selectedStand) {
      setNewOrder((prevState) => ({
        ...prevState,
        customerId: selectedStand?.customerId || '',
      }));
      fetchProductsByEvent(selectedStand?.eventId!, true);
      fetchOrderItemsByEventId(selectedStand?.eventId!, true);
    }
  }, [selectedStand, fetchProductsByEvent, fetchOrderItemsByEventId]);

  const handleCreateOrder = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    if (!values.orderNumber) {
      notifyError(t('stand.messageNotifier.numberRequired'));
      return;
    }

    try {
      const orderToCreate: NewOrder = {
        ...newOrder,
        orderNumber: values.orderNumber,
        totalPrice: values.totalPrice,
        customerId: selectedStand?.customerId || '',
        status: OrderStatus.Pending,
        eventId: selectedStand?.eventId || ''
      };

      await createOrder(orderToCreate as any);

      setNewOrder({
        id: uuidv4(),
        standId: id || '',
        orderNumber: uuidv4().substring(0, 8).toUpperCase(),
        totalPrice: 0,
        customerId: selectedStand?.customerId || '',
        status: OrderStatus.Pending,
        eventId: selectedStand?.eventId || '',
      });
      resetForm();
      setIsOrderModalVisible(false);
      fetchOrdersByStandId(id!);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedToCreateOrder'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleCreateOrderItem = async (values: FormikValues) => {
    if (!currentOrder) return;
    if (!values.productId) return;
    const filteredOrderItemsData = [];

    try {
      let orderItem: OrderItem;
      if (selectedOrderItem?.id) {
        orderItem = { ...values, orderId: currentOrder.id, eventId: selectedStand?.event?.id } as OrderItem;
        await updateOrderItem(selectedOrderItem.id, orderItem)
      } else {
        orderItem = { ...values, orderId: currentOrder.id!, id: uuidv4(), eventId: selectedStand?.event?.id } as OrderItem;
        await createOrderItem(orderItem);
      }
      filteredOrderItemsData.push(orderItem);
      filteredOrderItemsData.push(...orderItemsByEvent.filter(x => x.orderId === currentOrder.id && x.id !== orderItem.id));
      const orderData = {
        ...currentOrder,
        totalIssued: filteredOrderItemsData.map(orderItem => orderItem.issuedItems?.totalQuantity || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
        totalClean: filteredOrderItemsData.map(orderItem => orderItem.cleanItems?.totalQuantity || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
        totalUnused: filteredOrderItemsData.map(orderItem => orderItem.unusedItems?.totalQuantity || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
        totalUsed: filteredOrderItemsData.map(orderItem => orderItem.usedItems?.totalQuantity || 0).reduce((accumulator, currentValue) => accumulator + currentValue),
      };

      setCurrentOrder(orderData);
      await updateOrder(currentOrder.id, orderData)

      setSelectedOrderItem(orderItem);
      setIsOrderItemModalVisible(false);
      await fetchOrdersByStandId(id!);
      await fetchOrderItemsByEventId(selectedStand?.eventId!, true);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedToSubmitOrder'));
    }
  };

  const handleDeleteOrder = async (orderId: string) => {
    try {
      await deleteOrder(orderId);
      await fetchOrdersByStandId(id!);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedToDelteOrder'));
    }
  };

  const handleDeleteOrderItem = async (orderItemId: string) => {
    try {
      await deleteOrderItem(orderItemId);
      await fetchOrdersByStandId(id!);
      await fetchOrderItemsByEventId(selectedStand?.event?.id!, true);
    } catch (error) {
      notifyError(t('stand.messageNotifier.failedTodeleteOrderItem'));
    }
  };

  const handleEditOrderItem = async (orderItemId: string, orderItems: OrderItem[], order: Order) => {
    const currentOrderItem = orderItems.find(oi => oi.id == orderItemId);
    if (currentOrderItem) {
      setSelectedOrderItem({ ...currentOrderItem } as OrderItem);
      setCurrentOrder(order);
      setIsOrderItemModalVisible(true);
      await fetchOrderItemsByEventId(selectedStand?.event?.id!, true);
    }
  };

  const handlCloseModalOrderItem = async () => {
    setSelectedOrderItem(null);
    setIsOrderItemModalVisible(false);
  };

  const handleDownloadPDFByBillType = async (orderId: string, key: string) => {
    try {
      await downloadPDFByBillType(orderId, key);
    } catch (error) {
      notifyError(`${t('general.errorDownloadingPDF')}: ${error}`);
    }
  };

  if (!selectedStand) {
    return <div>{t('stand.standNotFound')}</div>;
  }

  const orderColumns: DataTableColumn<Order>[] = [
    { header: t('order.number'), accessor: 'orderNumber' },
    { header: t('order.totalIssued'), accessor: 'totalIssued', type: 'number' },
    { header: t('order.totalUsed'), accessor: 'totalUsed', type: 'number' },
    { header: t('order.totalUnused'), accessor: 'totalUnused', type: 'number' },
    { header: t('order.totalClean'), accessor: 'totalClean', type: 'number' },
  ];

  const orderItemColumns: DataTableColumn<OrderItem>[] = [
    { header: t('order.productName'), accessor: 'product.name' },
    { header: t('order.totalIssued'), accessor: 'issuedItems.totalQuantity', type: 'number' },
    { header: t('order.totalUsed'), accessor: 'usedItems.totalQuantity', type: 'number' },
    { header: t('order.totalUnused'), accessor: 'unusedItems.totalQuantity', type: 'number' },
    { header: t('order.totalClean'), accessor: 'cleanItems.totalQuantity', type: 'number' },
  ];

  const downloadColumns: { key: string, displayValue: string }[] = [
    { key: 'deliverynote', displayValue: t('stand.deliveryNote') },
    { key: 'redemptionslip', displayValue: t('stand.redemptionSlip') }
  ];

  const expandedRowRender = (order: Order) => {
    const filteredOrderItemsData = orderItemsByEvent.filter(x => x.orderId === order?.id);

    return <>
      {currentUser?.role == 'admin' &&
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          onClick={() => {
            setCurrentOrder(order);
            setIsOrderItemModalVisible(true);
            setSelectedOrderItem({} as any)
          }}
        >
          {t('stand.addItem')}
        </Button>
      }
      <DataTable
        columns={orderItemColumns}
        data={filteredOrderItemsData}
        onDelete={handleDeleteOrderItem}
        onEdit={currentUser?.role == 'admin' ? async (orderItemId) => await handleEditOrderItem(orderItemId, filteredOrderItemsData, order) : undefined}
        hidePagination
      />
    </>
  }

  return (
    <>
      <div style={{ border: '1px solid #f0f2f5', padding: '20px', borderRadius: '5px', marginBottom: '20px' }}>
        <Row justify="space-between">
          <Col>
            <Title level={2} style={{ margin: 0 }}>{selectedStand.name}</Title>
            <Paragraph style={{ margin: '5px 0' }}>
              <strong>{t('stand.customer')}</strong> <Link to={`/customers/${selectedStand.customer?.id}`}>{selectedStand.customer?.companyName}</Link>
            </Paragraph>
            <Paragraph style={{ margin: '5px 0' }}>
              <strong>{t('stand.type')}</strong> {selectedStand.type?.name}
            </Paragraph>
            <Paragraph style={{ margin: '5px 0' }}>
              <strong>{t('stand.sector')}</strong> {selectedStand.sector?.name}
            </Paragraph>
          </Col>
        </Row>
      </div>

      <Card style={{ marginTop: '1em' }}>
        <Title level={3}>{t('stand.deliveryBills')}</Title>
        {currentUser?.role == 'admin' &&
          <Button style={{ marginBottom: '1em' }} type="primary" icon={<PlusOutlined />} onClick={() => setIsOrderModalVisible(true)}>
            {t('stand.createDeliveryBill')}
          </Button>
        }
        <DataTable
          columns={orderColumns}
          data={selectedStandOrders}
          onRowClick={(order: Order) => {
            setCurrentOrder(order);
          }}
          expandedRowRender={expandedRowRender}
          onDelete={handleDeleteOrder}
          onDownload={handleDownloadPDFByBillType}
          downloadColumns={downloadColumns}
          defaultExpandAllRows
        />
      </Card>

      <Modal
        title={t('stand.createOrder')}
        open={isOrderModalVisible}
        footer={null}
        onCancel={() => setIsOrderModalVisible(false)}
      >
        <NewOrderForm
          initialValues={newOrder}
          onSubmit={handleCreateOrder}
          onCancel={() => setIsOrderModalVisible(false)}
        />
      </Modal>

      <Modal
        title={selectedOrderItem?.id ? t('stand.editOrderItem') : t('stand.addOrderItem')}
        open={isOrderItemModalVisible}
        footer={null}
        onCancel={() => handlCloseModalOrderItem()}
        width={"80%"}
      >
        <OrderItemForm
          initialValues={selectedOrderItem}
          onSubmit={handleCreateOrderItem}
          onCancel={() => handlCloseModalOrderItem()}
          products={productsOfSelectedEvent}
        />
      </Modal>
    </>
  );
};

export default StandDetails;
