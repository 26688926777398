import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['en', 'de'];
const cachedLang = localStorage.getItem('i18nextLng');

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: cachedLang || 'de',
    fallbackLng: cachedLang || 'de',
    debug: false,
    whitelist: languages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
