import React, { useState, useEffect } from 'react';
import { FormikValues } from 'formik';
import useProductTypeStore from '../../store/productTypeStore';
import { ProductType } from '../../models/productTypes/productType';
import DataFormModal from '../DataForm/DataFormModal';
import DataForm from '../DataForm/DataForm';
import { ProductTypeFormModalProps } from '../../models/productTypes/productTypeFormModal';
import { useTranslation } from 'react-i18next';
const { v4: uuidv4 } = require('uuid');

const ProductTypeFormModal: React.FC<ProductTypeFormModalProps> = ({
  open,
  onClose,
  initialValues,
}) => {
  const { createItem, updateItem } = useProductTypeStore();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [initialValues]);

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: any) => {
    try {
      if (isEditMode && initialValues) {
        await updateItem(values.id!, {
          ...values,
        });
      } else {
        await createItem({
          id: uuidv4(),
          ...values,
        } as ProductType);
      }
      resetForm();
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DataFormModal<ProductType>
      open={open}
      title={isEditMode ? t('productTypes.editProductType') : t('productTypes.createProductType')}
      onClose={onClose}
      initialValues={
        initialValues || {
          id: uuidv4(),
          name: '',
          notes: '',
        }
      }
      onSubmit={handleSubmit}
      fields={[
        { label: t('productTypes.name'), name: 'name', type: 'text', placeholder: t('productTypes.placeholderEnterTypeName') },
        {
          label: t('productTypes.notes'),
          name: 'notes',
          type: 'textarea',
          placeholder: t('productTypes.placeholderEnterNotes'),
        },
      ]}
      FormComponent={DataForm as any}
    />
  );
};

export default ProductTypeFormModal;
