import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import DataTable, { DataTableColumn } from '../../components/DataTable/DataTable';
import useStandTypeStore from '../../store/standTypeStore';
import StandTypeFormModal from '../../components/StandTypes/StandTypeFormModal';
import { StandType } from '../../models/standTypes/StandType';
import Card from 'antd/es/card/Card';
import { useTranslation } from 'react-i18next';

const StandTypesPage: React.FC = () => {
  const { items: items, selectedItem, fetchItems, setSelectedItem, deleteItem } = useStandTypeStore();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  const handleEdit = (id: string) => {
    const item = items.find((st) => st.id === id);
    if (item) {
      setSelectedItem(item);
      setIsModalVisible(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };

  const columns: DataTableColumn<StandType>[] = [
    { header: t('general.name'), accessor: 'name' },
    { header: t('general.notes'), accessor: 'notes' },
  ];

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <>
      <Card
        title={
          <>
            <span style={{ marginRight: '5px' }}>{t('stand.standTypes')}</span>
            <Button type="primary" onClick={() => setIsModalVisible(true)}>
              {t('stand.addStandType')}
            </Button>
          </>
        }
      >
        <DataTable
          columns={columns}
          data={items}
          onDelete={deleteItem}
          onEdit={handleEdit}
          enableFrontendFiltering
        />
      </Card>
      <StandTypeFormModal
        open={isModalVisible}
        onClose={handleCloseModal}
        initialValues={selectedItem!}
      />
    </>
  );
};

export default StandTypesPage;
